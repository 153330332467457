import React, { Fragment, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import pageStyles from 'css/page-style.module.css';
import { item_name_pipe, number_format_pipe } from 'pipes';
import { useItem } from 'contexts/item.context';
import InfoRow from 'components/InfoRow';
import Nodata from 'components/Nodata';
import useSpaceAuthinfo from 'hooks/useSpaceAuthinfo';
import { AuthCode } from 'constants/auth-codes.constants';


const ItemInfo = () => {
    const item = useItem();

    const spaceAuthinfo = useSpaceAuthinfo();

    const haveAssetViewAuth = useMemo(() => {
        if (spaceAuthinfo) {
            return spaceAuthinfo[AuthCode.spaceOwner] || spaceAuthinfo[AuthCode.assetView];
        }
    }, [spaceAuthinfo])

    if (!item) return null;

	return (
		<Box>
			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>기본정보</Typography>
				</Box>
				<div className={pageStyles.table}>
					{InfoRow('제품', item_name_pipe(item))}
                    {InfoRow('코드', item.code)}
                    {InfoRow('순번', item.order)}
                    {item.props.map(prop => {
                        const value = prop.type === 'string' ? prop.values.val : prop.values.numVal;
                        return (
                            <Fragment key={prop.id}>
                                {InfoRow(prop.name, value)}
                            </Fragment>
                        );
                    })}
                    {spaceAuthinfo[AuthCode.assetView] && InfoRow('단가', number_format_pipe(item.asset?.price))}
				</div>
			</div>

			<div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>창고 내 수량</Typography>
				</Box>
				<div className={pageStyles.table}>
                    {item.places.map(place => {
                        return (
                            <Fragment key={place.id}>
                                {InfoRow(place.name, place.placeItems.amount)}
                            </Fragment>
                        );
                    })}
                    {item.places.length === 0 && <Nodata>정보가 없습니다.</Nodata>}
				</div>
			</div>

            {haveAssetViewAuth && <div className={pageStyles.section}>
				<Box display={'flex'} alignItems={'center'} marginBottom={1}>
					<Typography variant="subtitle1" style={{ marginBottom: 0 }}>창고별 자산</Typography>
				</Box>
				<div className={pageStyles.table}>
                    {item.places.map(place => {
                        return (
                            <Fragment key={place.id}>
                                {InfoRow(place.name, (!isNaN(place.placeItems.amount) && (!isNaN(item.asset?.price))) ? number_format_pipe(place.placeItems.amount * item.asset?.price) : '')}
                            </Fragment>
                        );
                    })}
                    {item.places.length === 0 && <Nodata>정보가 없습니다.</Nodata>}
				</div>
			</div>}
		</Box>
	);
}

export default ItemInfo;