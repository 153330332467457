import React from 'react';
import { Box } from '@mui/material';
import docuStyles from 'css/docu-style.module.css';
import H4 from 'components/document/H4';
import H5 from 'components/document/H5';
import Body1 from 'components/document/Body1';
import Glossary from 'components/document/Glossary';
import Emphasize from 'components/document/Emphasize';
import Tip from 'components/document/Tip';


const ActionHomePage = () => {
	return (
		<Box>
            <H4>액션</H4>

            <Glossary>
                <Body1 style={{ margin: 0 }}>
                    <span style={{ fontWeight: 'bold' }}>액션</span><br></br>
                    액션은 창고의 기본 수행동작으로 입고, 출고, 이동, 조정 이 있습니다.<br></br>
                    기본적으로 액션은 다음의 상태를 가집니다.: 미수행 / 수행완료<br></br>
                    액션승인을 권한을 가진 사용자가 수행처리하면 창고 내 제품 수량에 반영됩니다.
                </Body1>
            </Glossary>

            <Box sx={{ mt: 4 }}>
                <Emphasize>
                    <Body1>
                        관련권한: <span className={docuStyles.emphasize}>액션: 입고, 액션: 출고, 액션: 이동, 액션: 조정, 액션: 승인</span>
                    </Body1>
                </Emphasize>
            </Box>
            
            <Box className={docuStyles.section}>
                <H5>창고관리</H5>
                <Body1>창고의 제품에 대해 다음의 액션을 할 수 있습니다.</Body1>
                <Box className={docuStyles.subsection}>
                    <ul>
                        <li><Body1>입고: 창고에 제품을 입고합니다. 거래처를 선택할 수 있습니다.</Body1></li>
                        <li><Body1>출고: 창고에 제품을 출고합니다. 거래처를 선택할 수 있습니다.</Body1></li>
                        <li><Body1>이동: 다른 창고로 제품을 이동합니다.</Body1></li>
                        <li><Body1>조정: 창고 내 제품 수량을 조정합니다. 재고 수량의 강제 조정이 필요할 때 사용합니다.</Body1></li>
                    </ul>
                </Box>
            </Box>

            <Box className={docuStyles.section}>
                <H5>입출고명세서</H5>
                <Body1>입출고명세서는 여러 액션에 대한 품의서입니다. 문서단위로 승인되며 승인시 입출고명세서의 모든 액션이 수행됩니다.</Body1>
                <Body1>(액션: 승인) 권한을 가진자가 입출고명세서를할 승인할 수 있습니다.</Body1>
            </Box>

            <Tip>
                <Body1 style={{ margin: 0 }}>
                    <span style={{ fontWeight: 'bold' }}>창고의 재고량은 최초에 입력해야 하나요?</span><br></br>
                    입고, 출고, 이동을 자유롭게 하세요. <br></br>
                    이후 재고량을 알게 된다면 조정 을 통해 수량을 강제 조정하실 수 있습니다.
                </Body1>
            </Tip>
		</Box>
	);
}

export default ActionHomePage;