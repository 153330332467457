import { AuthCode } from "constants/auth-codes.constants";
import { useMemo } from "react";
import useSpaceAuthinfo from "./useSpaceAuthinfo";


export default function (authcode) {
    const spaceAuthinfo = useSpaceAuthinfo();

    const haveAssetViewAuth = useMemo(() => {
        if (spaceAuthinfo) {
            return spaceAuthinfo[AuthCode.spaceOwner] || spaceAuthinfo[authcode];
        }
    }, [spaceAuthinfo])


    return haveAssetViewAuth;
}